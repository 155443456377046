/* Fonts Import */
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

/* Fonts Import Ends */

body {
  margin: 0;
  font-family: 'Montserrat', Helvetica, Arial, Lucida, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.cardconatiner {
  height: 100%;
  /* width: 100%; */
}
