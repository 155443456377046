@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
/* Fonts Import */

/* Fonts Import Ends */

body {
  margin: 0;
  font-family: 'Montserrat', Helvetica, Arial, Lucida, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.cardconatiner {
  height: 100%;
  /* width: 100%; */
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  font-family: 'Montserrat', Helvetica, Arial, Lucida, sans-serif;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(148, 147, 147);
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.test {
  background-color: rgb(172, 169, 169);
}

.discount-container {
  display: flex;
  justify-content: space-between;
  font-style: italic;
  font-weight: 600;
  font-size: 13px;
  margin: 20px 0;
}

.csv {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
}

.sample-csv-link {
  color: rgb(210, 171, 101);
  text-decoration: none;
  font-weight: bold;
}

.upload-csv {
  display: flex;
  margin-bottom: 20px;
}

.upload-csv > label {
  text-decoration: underline;
  cursor: pointer;
  font-size: 13px;
}

.upload-csv > input {
  display: none;
}

.cancelbtn {
  padding: 5px 10px;
  border: solid 2px #993399;
  border-radius: 5px;
  cursor: pointer;
  color: #ffffff;
  background: #000000;
}

.pagetwo {
  height: 60vh;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
}

.counter {
  height: 20vh;
  width: 100%;
  display: flex;
  /*background-color: #1b1b1b;*/
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

.counter p span {
  margin-left: 10px;
  font-weight: 900;
}

.links {
  text-decoration: none;
  cursor: pointer;
  font-weight: 600;
  padding: 12px;
  border: 1px solid #b64ef9;
  border-radius: 5px;
  position: relative;
  left: 70px;
}
.stepthree {
  border-radius: 8px;
  margin-top: 20%;
  height: 40vh;
  width: 90%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  font-weight: 600;
}

.disclaimer {
  font-size: 12px;
  height: 7vh;
  width: 100%;
  color: #00e5ff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalswitcher {
  border: 2px solid rgb(153, 51, 153);
  font-weight: 600;
  font-size: 14px;
  background-color: #b64ef9;
  box-shadow: 0px 4px 20px 0px rgb(148 227 255 / 26%);
  min-width: 105px;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  transition: -webkit-transform 2s;
  transition: transform 2s;
  transition: transform 2s, -webkit-transform 2s;
}

.modalswitcherLanding {
  /* border: 2px solid rgb(153, 51, 153); */
  font-weight: 600;
  font-size: 14px;
  /* background-color: #b64ef9; */
  background-image: linear-gradient(90deg, #e84ad2 0%, #4b9be0 100%);
  box-shadow: 0px 4px 20px 0px rgb(148 227 255 / 26%);
  min-width: 105px;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  transition: -webkit-transform 2s;
  transition: transform 2s;
  transition: transform 2s, -webkit-transform 2s;
}
.modalswitcherLanding:hover {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
}

.modalswitcher2 {
  border: 2px solid rgb(153, 51, 153);
  font-weight: 600;
  font-size: 14px;
  background-color: #323232;
  background-image: linear-gradient(90deg, #e84ad2 0%, #4b9be0 100%);
  box-shadow: 0px 4px 20px 0px rgb(148 227 255 / 26%);
  min-width: 105px;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
}
.modalswitcher-nav {
  border: 2px solid rgb(153, 51, 153);
  font-weight: 600;
  font-size: 12px;
  background-color: #b64ef9;
  min-width: 105px;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
}
.modalswitcher1 {
  border: none;
  font-weight: 600;
  font-size: 12px;
  background-color: inherit;
  min-width: 105px;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
}

.nowalletmodal {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nowalletmodal2 {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background: rgba(25, 25, 25, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

.submodal {
  min-height: 60vh;
  padding: 20px;
  min-width: 35%;
  background-color: #191919;
  border-radius: 8px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
}
.submodal2 {
  min-height: 40vh;
  padding: 20px;
  min-width: 30%;
  background-color: #191919;
  border-radius: 8px;
  border: solid 1px #b64ef9;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
}

.inputsc {
  width: 90%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  color: white;
}
label {
  font-size: 18px;
  padding: 5px;
  cursor: pointer;
}
label:hover,
label:active {
  font-weight: 600;
}
legend {
  font-weight: 800;
}
img.discord {
  padding: 20px;
  height: 35px;
}
a:link {
  text-decoration: none;
}
.inputsc p input {
  border: 1px solid #c5c5c5;
  background-color: #323232;
  border-radius: 5px;
  height: 25px;
  width: 60%;
  color: white;
  margin-left: 10px;
  margin-top: 20px;
}

.inputsc p {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.inputsc p input:focus {
  outline: none;
}
.headtext {
  color: white;
  text-align: center;
  font-size: 16px;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #323232;
}

#importantinfo {
  height: 55px;
  width: 100%;
  position: absolute;
  top: 2%;
  left: 0%;
  color: white;
}

@media (max-width: 700px) {
  .serviceFee-container {
    font-size: 10px;
    display: flex;
    background-color: #283830;
    margin: 3px 0px;
  }
  .sf-block {
    padding: 5px;
    text-align: center;
    border: solid;
  }
  .text2 {
    color: #1bfb76;
    font-size: 10px;
  }
  .text3 {
    font-style: italic;
    color: white;
    font-size: 8px;
  }
  .bold {
    font-weight: bold;
    color: white;
  }
  .bold2 {
    font-weight: bold;
    color: white;
    font-style: italic;
  }

  .br {
    border-right: 0.5px solid black;
  }
  .disclaimer {
    font-size: 10px;
  }
  .counter {
    width: 100%;
  }
  .modalswitcher {
    min-width: 90px;
    padding: 6px 0 6px 0;
    font-size: 9px;
  }

  .modalswitcher-nav {
    display: none;
  }
  .modalswitcher1 {
    min-width: 90px;
    padding: 6px 0 6px 0;
    font-size: 9px;
  }
  .headtext {
    font-size: 12px;
    width: 89%;
  }
  .nowalletmodal .inputsc p {
    font-size: 10px;
    width: 92%;
  }
}

.feebor div {
  height: 50%;
  padding-top: 10%;
}

@media (max-width: 319px) {
  .modalswitcher {
    min-width: 90px !important;
    padding: 6px 0 6px 0 !important;
    font-size: 9px !important;
  }
  .modalswitcher1 {
    min-width: 90px !important;
    padding: 6px 0 6px 0 !important;
    font-size: 9px !important;
  }
}
@media (max-width: 1024px) {
  .dropdown {
    margin-right: 10px !important;
  }
}
.headtext {
  font-size: 12px;
}
p.headtextp {
  font-size: 14px;
}

@media (max-width: 1024px) {
  .dropdown {
    margin-right: 10px !important;
  }
}
@media (min-width: 701px) and (max-width: 1023px) {
  .submodal {
    width: 57%;
    height: 70%;
  }
  .headtext {
    font-size: 15px;
  }
  .disclaimer {
    font-size: 20px;
  }
  .counter {
    width: 100%;
  }
  .counter p {
    font-size: 20px;
  }
  .inputsc p {
    font-size: 14px;
  }
}
@media (min-width: 1024px) and (max-width: 1280px) {
  .headtext {
    font-size: 18px;
  }
  .submodal {
    width: 55%;
  }
  .feebor {
    font-size: 11px !important;
  }
}

.feedis {
  height: 14vh;

  width: 90%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.feebor {
  border: 2px outset rgb(153, 51, 153);
  border-radius: 2px;

  width: 20%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #00e5ff;
  font-size: 12px;
}

.feerec {
  margin-top: 7px;
  width: 100%;
  border-top: 2px solid rgb(153, 51, 153);
}
@media (max-width: 700px) {
  .feebor {
    font-size: 7px;
  }
  #importantinfo {
    font-size: 13px;
  }
}
@media (max-width: 280px) {
  .nowalletmodal .inputsc p {
    width: 100%;
  }
}
@media (max-width: 319px) {
  img.discord {
    padding: 0px;
    height: 25px;
  }
}

#homebtn {
  border: none;
  cursor: pointer;
  color: white;
  font-weight: 600;
  padding: 15px;
  border-radius: 5px;
  background-color: #b64ef9;
  position: relative;
  bottom: 177px;
  right: 70px;
}

#homebtn:hover {
  background-color: green;
  font-weight: 700;
  color: #000000;
}

.des {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* flex-direction: column; */
  width: 100%;
}

.subdes {
  padding: 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.des img {
  height: 20px;
  width: 20px;
}

.tokencontainer {
  padding: 10px 20px;
  margin: 20px auto;

  width: 90%;
  border-radius: 5px;
  background-color: #333333;
  position: absolute;
  top: 25px;
  left: 0px;
  border: 2px solid #ff3399;

  font-size: 14px;
  font-weight: 600;
  max-height: 50vh;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.tokencontainer::-webkit-scrollbar {
  display: none;
}

.tokenrow {
  width: 100%;
  margin: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #1bfb76;
  border-radius: 5px;
  padding: 10px;
}

.imgcon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
}

.imgcon img {
  height: 24px;
  width: 24px;
  margin-right: 10px;
}

.displayamt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: #00e5ff; */
  width: 90%;
}

@media screen and (max-width: 950px) {
  #sidenav {
    display: none;
  }
}
#sidenav {
  display: none;
}

::-webkit-scrollbar {
  display: hidden;
}
/* width */
::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

